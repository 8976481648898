<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">
        运费设置
      </div>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="getTongYi">
      <el-tab-pane v-if="MixinIsFormEnterprise" label="企业运费设置" name="1"></el-tab-pane>
      <el-tab-pane label="用户运费设置" name="2"></el-tab-pane>
    </el-tabs>
    <el-form class="demo-ruleForm" :model="params" ref="params" :rules="rules" label-width="100px">
      <el-form-item label="运费模式选择" label-width="100px">
        <div style="margin-bottom:10px">
        <el-radio v-model="params.model" label="FREE_SHIPPING">免运费</el-radio><br />
        <el-radio v-model="params.model" label="UNIFORM_FREIGHT">所有商品统一设置运费模式</el-radio><br />
        <el-radio v-model="params.model" label="BY_GOODS_SOURCE">针对商品来源设置运费模式</el-radio><br />
        
          <!-- <el-radio-group v-model="params.model">
            <p v-for="(item, index) in modelList" :key="index">
              <el-radio :label="item.value">{{ item.name }}</el-radio><br />
            </p>
          </el-radio-group> -->
        </div>
      </el-form-item>
      <el-form-item :label="`${activeName === '1' ? '企业' : '用户'}运费设置`" label-width="100px"
        v-if="params.model === 'UNIFORM_FREIGHT'">
        <span style="color: #cccccc; font-size: 13px;">
          说明： {{ activeName ===
      '1' ? '企业运费用于企业向站点商城收取运费，运费的满足金额以订单中，商品的分销价为标准。' : '用户运费用于收取用户在商城消费时产生的运费，运费的满足金额以订单中，商品的销售价为标准。' }}
        </span>
        <br>
        <span>{{ activeName === '1' ? '企业' : '用户' }}运费按照满</span>
        <el-form-item style="display: inline-block" prop="uniform_freight.min" :rules="rules.min">
          <el-input v-model="params.uniform_freight.min" placeholder="输入金额" size="small"
            @input="handleInput(3, params.uniform_freight.min, 0)"></el-input>
          <span>元包邮,不满则收取</span>
        </el-form-item>
        <el-form-item style="display: inline-block" prop="uniform_freight.money" :rules="rules.money">
          <el-input v-model="params.uniform_freight.money" placeholder="输入金额" size="small"
            @input="handleInput(4, params.uniform_freight.money, 0)"></el-input>
          <span>元运费。</span>
        </el-form-item>
      </el-form-item>
     
      <el-form-item :label="activeName === '1'?'企业运费设置':'用户运费设置'" label-width="100px" v-show="params.model === 'BY_GOODS_SOURCE'">
        <span v-if="activeName === '1'" style="color: #cccccc; font-size: 13px;">
        说明：企业运费会按照供应商来源不同分开收运费，运费金额随订单扣减 站点账户余额一并扣除。
        </span>
        <span v-else style="color: #cccccc; font-size: 13px;">
          说明：用户运费不会按照供应商分开收运费，每个订单，只会收取用户一次运费，运费金额随用户支付订单一起支付。
        </span>
        <div class="ssf">
          <div class="ssf-title">
            <b>京东商品运费:</b>
            <p>
              平台商品运费规则:订单金额 < 69元，收取运费6元；订单金额 ≥ 69元， 免运费 </p>
                <p>
                  （未设置运费规则的价格区间按照免运费来计算）
                </p>
          </div>
          <div v-for="(k, index) in params.by_goods_source.JD_GOODS" :key="index"
            class="row no-gutters align-items-center">
            <div class="col-auto">
              <span>订单中商品金额在</span>
              <el-form-item class="flexitem" :prop="`by_goods_source.JD_GOODS[${index}].min`" :rules="rules.list_min">
                <el-input v-model="k.min" placeholder="0.0" size="small"
                  @input="handleInput(0, k.min, index, 'JD_GOODS')"></el-input>
                <span>元(包含)至</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.JD_GOODS[${index}].max`" :rules="rules.list_max">
                <el-input v-model="k.max" placeholder="0.0" size="small"
                  @input="handleInput(1, k.max, index, 'JD_GOODS')"></el-input>
                <span>元(不含),收取</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.JD_GOODS[${index}].money`"
                :rules="rules.list_money">
                <el-input v-model="k.money" placeholder="0.0" size="small"
                  @input="handleInput(2, k.money, index, 'JD_GOODS')"></el-input>
                <span>元运费。</span>
              </el-form-item>
            </div>

            <div class="col-auto">
              <el-button v-if="index === 0" type="primary" @click="pub('JD_GOODS')" size="small">添加</el-button>
              <el-button v-else type="danger" @click="del(index, 'JD_GOODS')" size="small">删除</el-button>
            </div>
          </div>
        </div>
        <div class="ssf">
          <div class="ssf-title">
            <b>精选优品商品运费:</b>
            <p>所有平台精选优品类商品按照同一种运费规则来计算。</p>
            <p>（未设置运费规则的价格区间按照免运费来计算）</p>
          </div>
          <div v-for="(k, index) in params.by_goods_source.NORMAL" :key="index"
            class="row no-gutters align-items-center">
            <div class="col-auto">
              <span>订单中商品金额在</span>
              <el-form-item class="flexitem" :prop="`by_goods_source.NORMAL[${index}].min`" :rules="rules.list_min">
                <el-input v-model="k.min" placeholder="0.0" size="small"
                  @input="handleInput(0, k.min, index, 'NORMAL')"></el-input>
                <span>元(包含)至</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.NORMAL[${index}].max`" :rules="rules.list_max">
                <el-input v-model="k.max" placeholder="0.0" size="small"
                  @input="handleInput(1, k.max, index, 'NORMAL')"></el-input>
                <span>元(不含),收取</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.NORMAL[${index}].money`"
                :rules="rules.list_money">
                <el-input v-model="k.money" placeholder="0.0" size="small"
                  @input="handleInput(2, k.money, index, 'NORMAL')"></el-input>
                <span>元运费。</span>
              </el-form-item>
            </div>

            <div class="col-auto">
              <el-button v-if="index === 0" type="primary" @click="pub('NORMAL')" size="small">添加</el-button>
              <el-button v-else type="danger" @click="del(index, 'NORMAL')" size="small">删除</el-button>
            </div>
          </div>
        </div>


        <!-- <div class="ssf">
          <div class="ssf-title">
            <b>蛋糕类商品运费:</b>
            <p>所有平台接入的蛋糕类商品按照同一种运费规则来计算。 </p>
            <p>（未设置运费规则的价格区间按照免运费来计算）</p>
          </div>
          <div v-for="(k, index) in params.by_goods_source.UNCLE_CAKE_GOODS" :key="index"
            class="row no-gutters align-items-center">
            <div class="col-auto">
              <span>订单中商品金额在</span>
              <el-form-item class="flexitem" :prop="`by_goods_source.UNCLE_CAKE_GOODS[${index}].min`" :rules="rules.list_min">
                <el-input v-model="k.min" placeholder="0.0" size="small"
                  @input="handleInput(0, k.min, index, 'UNCLE_CAKE_GOODS')"></el-input>
                <span>元(包含)至</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.UNCLE_CAKE_GOODS[${index}].max`" :rules="rules.list_max">
                <el-input v-model="k.max" placeholder="0.0" size="small"
                  @input="handleInput(1, k.max, index, 'UNCLE_CAKE_GOODS')"></el-input>
                <span>元(不含),收取</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.UNCLE_CAKE_GOODS[${index}].money`" :rules="rules.list_money">
                <el-input v-model="k.money" placeholder="0.0" size="small"
                  @input="handleInput(2, k.money, index, 'UNCLE_CAKE_GOODS')"></el-input>
                <span>元运费。</span>
              </el-form-item>
            </div>
            <div class="col-auto">
              <el-button v-if="index === 0" type="primary" @click="pub('UNCLE_CAKE_GOODS')"
                size="small">添加</el-button>
              <el-button v-else type="danger" @click="del(index, 'UNCLE_CAKE_GOODS')" size="small">删除</el-button>
            </div>
          </div>
        </div> -->


        <div class="ssf">
          <div class="ssf-title">
            <b>企业自有商品运费:</b>
            <p>当前企业自有商品按照同一种运费规则来计算。 </p>
            <p>（未设置运费规则的价格区间按照免运费来计算）</p>
          </div>
          <div v-for="(k, index) in params.by_goods_source.ENTERPRISE_SELF_GOODS" :key="index"
            class="row no-gutters align-items-center">
            <div class="col-auto">
              <span>订单中商品金额在</span>
              <el-form-item class="flexitem" :prop="`by_goods_source.ENTERPRISE_SELF_GOODS[${index}].min`"
                :rules="rules.list_min">
                <el-input v-model="k.min" placeholder="0.0" size="small"
                  @input="handleInput(0, k.min, index, 'ENTERPRISE_SELF_GOODS')"></el-input>
                <span>元(包含)至</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.ENTERPRISE_SELF_GOODS[${index}].max`"
                :rules="rules.list_max">
                <el-input v-model="k.max" placeholder="0.0" size="small"
                  @input="handleInput(1, k.max, index, 'ENTERPRISE_SELF_GOODS')"></el-input>
                <span>元(不含),收取</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.ENTERPRISE_SELF_GOODS[${index}].money`"
                :rules="rules.list_money">
                <el-input v-model="k.money" placeholder="0.0" size="small"
                  @input="handleInput(2, k.money, index, 'ENTERPRISE_SELF_GOODS')"></el-input>
                <span>元运费。</span>
              </el-form-item>
            </div>

            <div class="col-auto">
              <el-button v-if="index === 0" type="primary" @click="pub('ENTERPRISE_SELF_GOODS')"
                size="small">添加</el-button>
              <el-button v-else type="danger" @click="del(index, 'ENTERPRISE_SELF_GOODS')" size="small">删除</el-button>
            </div>
          </div>
        </div>
        <!-- 企业自有供应商商品运费受到大平台开关控制 -->
        <div class="ssf" v-if="numof_enterprise_owner_suppliers">
          <div class="ssf-title">
            <b>企业自有供应商商品运费:</b>
            <p>当前企业自有供应商商品按照同一种运费规则来计算。 </p>
            <p>（未设置运费规则的价格区间按照免运费来计算）</p>
          </div>
          <div v-for="(k, index) in params.by_goods_source.ENTERPRISE_OWNER_SUPPLIERS" :key="index"
            class="row no-gutters align-items-center">
            <div class="col-auto">
              <span>订单中商品金额在</span>
              <el-form-item class="flexitem" :prop="`by_goods_source.ENTERPRISE_OWNER_SUPPLIERS[${index}].min`"
                :rules="rules.list_min">
                <el-input v-model="k.min" placeholder="0.0" size="small"
                  @input="handleInput(0, k.min, index, 'ENTERPRISE_OWNER_SUPPLIERS')"></el-input>
                <span>元(包含)至</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.ENTERPRISE_OWNER_SUPPLIERS[${index}].max`"
                :rules="rules.list_max">
                <el-input v-model="k.max" placeholder="0.0" size="small"
                  @input="handleInput(1, k.max, index, 'ENTERPRISE_OWNER_SUPPLIERS')"></el-input>
                <span>元(不含),收取</span>
              </el-form-item>
              <el-form-item class="flexitem" :prop="`by_goods_source.ENTERPRISE_OWNER_SUPPLIERS[${index}].money`"
                :rules="rules.list_money">
                <el-input v-model="k.money" placeholder="0.0" size="small"
                  @input="handleInput(2, k.money, index, 'ENTERPRISE_OWNER_SUPPLIERS')"></el-input>
                <span>元运费。</span>
              </el-form-item>
            </div>

            <div class="col-auto">
              <el-button v-if="index === 0" type="primary" @click="pub('ENTERPRISE_OWNER_SUPPLIERS')"
                size="small">添加</el-button>
              <el-button v-else type="danger" @click="del(index, 'ENTERPRISE_OWNER_SUPPLIERS')" size="small">删除</el-button>
            </div>
          </div>
        </div>
        <div class="ssf" v-if="activeName == '2'">
          <div class="ssf-title">
            <b>商城自有商品运费:</b>
            <p>当前商城自有邮寄商品按照同一种运费规则来计算。 </p>
            <p>（未设置运费规则的价格区间按照免运费来计算）</p>
          </div>
          <div v-for="(k, index) in params.by_goods_source.SHOP_SELF_GOODS" :key="index"
            class="row no-gutters align-items-center">
            <div class="col-auto">
              <span>订单中商品金额在</span>
              <el-form-item class="flexitem" :prop="`by_goods_source.SHOP_SELF_GOODS[${index}].min`"
                :rules="rules.list_min">
                <el-input v-model="k.min" placeholder="0.0" size="small"
                  @input="handleInput(0, k.min, index, 'SHOP_SELF_GOODS')"></el-input>
                <span>元(包含)至</span>
              </el-form-item>

              <el-form-item class="flexitem" :prop="`by_goods_source.SHOP_SELF_GOODS[${index}].max`"
                :rules="rules.list_max">
                <el-input v-model="k.max" placeholder="0.0" size="small"
                  @input="handleInput(1, k.max, index, 'SHOP_SELF_GOODS')"></el-input>
                <span>元(不含),收取</span>
              </el-form-item>

              <el-form-item class="flexitem" :prop="`by_goods_source.SHOP_SELF_GOODS[${index}].money`"
                :rules="rules.list_money">
                <el-input v-model="k.money" placeholder="0.0" size="small"
                  @input="handleInput(2, k.money, index, 'SHOP_SELF_GOODS')"></el-input>
                <span>元运费。</span>
              </el-form-item>
            </div>

            <div class="col-auto">
              <el-button v-if="index === 0" type="primary" @click="pub('SHOP_SELF_GOODS')" size="small">添加</el-button>
              <el-button v-else type="danger" @click="del(index, 'SHOP_SELF_GOODS')" size="small">删除</el-button>
            </div>
          </div>
        </div>
      </el-form-item>
     
      <el-button class="fixedbtn" type="primary" @click="handleSaveShopData()">确定</el-button>
    </el-form>
  </el-card>
</template>

<script>
import { getTransPort, saveTransPort } from '@/api/systemSetting'
import { mapGetters } from 'vuex';
import { getShopExtAuth } from '@/api/order';
export default {
  computed: {
    ...mapGetters(['shopInfo'])
  },
  name: 'transportg',
  data () {
    return {
      modelList: [
        { value: 'FREE_SHIPPING', name: '免运费' },
        { value: 'UNIFORM_FREIGHT', name: '所有商品统一设置运费模式' },
        { value: 'BY_GOODS_SOURCE', name: '针对商品来源设置运费模式' }
      ],
      activeName: '1',
      params: {
        model: 'FREE_SHIPPING',//运费设置选择
        uniform_freight: { min: '', money: '' },//统一运费设置
        by_goods_source: {
          //京东商品运费
          JD_GOODS: [{
            max: '',
            min: '',
            money: ''
          }],
          //精选优品商品运费
          NORMAL: [{
            max: '',
            min: '',
            money: ''
          }],
          //蛋糕商品运费
          UNCLE_CAKE_GOODS: [{
            max: '',
            min: '',
            money: ''
          }],
          //企业自有商品运费
          ENTERPRISE_SELF_GOODS: [{
            max: '',
            min: '',
            money: ''
          }],
          //企业自有供应商商品运费
          ENTERPRISE_OWNER_SUPPLIERS: [{
            max: '',
            min: '',
            money: ''
          }],
          //商城自有商品运费
          SHOP_SELF_GOODS: [{
            max: '',
            min: '',
            money: ''
          }],
        }

      },
      rules: {
        min: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        list_min: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[1-9]\d*$|^[0-9]\d*.[0-9]{2}$|^[0-9]\d*.[0-9]$/
              const name = rule.field.split('.')[1].split('[')[0]
              const index = rule.field.split('.')[1].split('[')[1].charAt(0)
              const rule_one = this.params.by_goods_source[name][index].max != '' && this.params.by_goods_source[name][index].min == '';
              const rule_two = this.params.by_goods_source[name][index].max == '' && this.params.by_goods_source[name][index].min == '' && this.params.by_goods_source[name][index].money != '';
              if (rule_two) {
                callback(new Error('请输入金额'));
              }else if (this.params.by_goods_source[name][index].min != ''&& this.params.by_goods_source[name][index].max != '') {
                if (Number(this.params.by_goods_source[name][index].min) >= Number(this.params.by_goods_source[name][index].max)) {
                  callback(new Error('右侧金额需大于左侧金额'));
                }else callback();
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        list_max: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[1-9]\d*$|^[0-9]\d*.[0-9]{2}$|^[0-9]\d*.[0-9]$/
              const name = rule.field.split('.')[1].split('[')[0]
              const index = rule.field.split('.')[1].split('[')[1].charAt(0)
              if (this.params.by_goods_source[name][index].min != '') {
                if (Number(this.params.by_goods_source[name][index].min) >= Number(this.params.by_goods_source[name][index].max)) {
                  callback(new Error('右侧金额需大于左侧金额'));
                }else callback();
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        list_money: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[1-9]\d*$|^[0-9]\d*.[0-9]{2}$|^[0-9]\d*.[0-9]$/
              const name = rule.field.split('.')[1].split('[')[0]
              const index = rule.field.split('.')[1].split('[')[1].charAt(0)
              if (this.params.by_goods_source[name][index].min != '' && this.params.by_goods_source[name][index].max != '' && this.params.by_goods_source[name][index].money == '') {
                callback(new Error('请设置运费'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
      },
      numof_enterprise_owner_suppliers: false//企业自有供应商商品开关
    };
  },
  async mounted () {
    if (this.MixinIsFormEnterprise != 1) {
      this.activeName = '2'
    }
    //判断是否有企业自有供应商权限
    const resStatus = await getShopExtAuth().then();
    if (resStatus) {
      this.numof_enterprise_owner_suppliers = resStatus.numof_enterprise_owner_suppliers !== -1;
    }
    this.getTongYi();
  },
  methods: {
    handleInput (type, val, index, name) { 
      if (val!=='.'&&val.slice(-1) === '.'&&val.split('.').length-1 <2) {
        return true;
      }
      if (/^[0-9]\d*$|^[0-9]\d*.[0-9]\d*$/.test(val)) {
        let result =val;
        if (val.includes('.')) {
          const intPrice = val.split('.')[0]
          let flotPrice = val.split('.')[1]
          if (flotPrice.length > 2) {
            flotPrice = flotPrice.slice(0, 2)
          }
          result = intPrice.toString() + '.' + flotPrice.toString()
        }
        if (result>10000) result = 10000
        if (type === 0) {
          this.params.by_goods_source[name][index].min = result
        } else if (type === 1) {
          this.params.by_goods_source[name][index].max = result
        } else if (type === 2) {
          this.params.by_goods_source[name][index].money = result
        } else if (type === 3) {
          this.params.uniform_freight.min = result
        } else {
          this.params.uniform_freight.money = result
        }
      } else {
        if (type === 0) {
          this.params.by_goods_source[name][index].min = ''
        } else if (type === 1) {
          this.params.by_goods_source[name][index].max = ''
        } else if (type === 2) {
          this.params.by_goods_source[name][index].money = ''
        } else if (type === 3) {
          this.params.uniform_freight.min = ''
        } else {
          this.params.uniform_freight.money = ''
        }
      }
    },
    del (ind, name) {
      this.params.by_goods_source[name].splice(ind, 1)
    },
    pub (name) {
      let temp = {
        max: '',
        min: '',
        money: ''
      };
      this.params.by_goods_source[name].push(temp);
    },
    /*** 保存运费设置*/
    handleSaveShopData () {
     
      this.$refs.params.validate((valid) => {
        
        if (valid) {
          const params = {
            ...this.params,
            source: this.activeName == '1' ? 'ENTERPRISE' : 'SHOP',
          }
          // 企业运费传ENTERPRISE   店铺运费传SHOP
          saveTransPort(params).then(res => {
            this.$message.success('保存成功！');
            this.getTongYi();
          });
        } else {
          console.log(valid);
          this.$message.error('请填写完整信息！');
          return false;
        }
      });
    },
    /**查询运费详情 */
    getTongYi () {
      this.$refs['params'].resetFields();
      const contain_enterprise = this.activeName === '1' ? true : false;
      getTransPort({ contain_enterprise }).then(res => {
        if (res) {
          const { by_goods_source, uniform_freight, model } = res
          this.params = {
            uniform_freight: {
              min: '',
              money: ''
            },
            by_goods_source : {
              //京东商品运费
              JD_GOODS: [{ max: '', min: '', money: '' }],
              //精选优品商品运费
              NORMAL: [{ max: '', min: '', money: '' }],
              //蛋糕商品运费
              UNCLE_CAKE_GOODS: [{ max: '', min: '', money: '' }],
              //企业自有商品运费
              ENTERPRISE_SELF_GOODS: [{ max: '', min: '', money: '' }],
              //企业自有供应商商品运费
              ENTERPRISE_OWNER_SUPPLIERS: [{ max: '', min: '', money: '' }],
              //商城自有商品运费
              SHOP_SELF_GOODS: [{ max: '', min: '', money: '' }],
            },
            model: ''
          }
          this.params.model = model
          if (uniform_freight) {
            this.params.uniform_freight = res.uniform_freight;
          }
          if (by_goods_source && JSON.stringify(by_goods_source) != "{}") {
            if (res.by_goods_source.JD_GOODS&&JSON.stringify(res.by_goods_source.JD_GOODS) != "[]") this.params.by_goods_source.JD_GOODS = res.by_goods_source.JD_GOODS;
            if (res.by_goods_source.NORMAL&&JSON.stringify(res.by_goods_source.NORMAL) != "[]") this.params.by_goods_source.NORMAL = res.by_goods_source.NORMAL;
            if (res.by_goods_source.UNCLE_CAKE_GOODS&&JSON.stringify(res.by_goods_source.UNCLE_CAKE_GOODS) != "[]") this.params.by_goods_source.UNCLE_CAKE_GOODS = res.by_goods_source.UNCLE_CAKE_GOODS;
            if (res.by_goods_source.ENTERPRISE_SELF_GOODS&&JSON.stringify(res.by_goods_source.ENTERPRISE_SELF_GOODS) != "[]") this.params.by_goods_source.ENTERPRISE_SELF_GOODS = res.by_goods_source.ENTERPRISE_SELF_GOODS;
            //企业自有供应商商品运费
            if (res.by_goods_source.ENTERPRISE_OWNER_SUPPLIERS && JSON.stringify(res.by_goods_source.ENTERPRISE_OWNER_SUPPLIERS) != "[]") this.params.by_goods_source.ENTERPRISE_OWNER_SUPPLIERS = res.by_goods_source.ENTERPRISE_OWNER_SUPPLIERS;
            if (res.by_goods_source.SHOP_SELF_GOODS&&JSON.stringify(res.by_goods_source.SHOP_SELF_GOODS) != "[]") this.params.by_goods_source.SHOP_SELF_GOODS = res.by_goods_source.SHOP_SELF_GOODS;
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.ss {
  color: #999;
}

.ssf {
  line-height: 1.7;
  margin-top: 8px;

  .ssf-title {
    b {
      font-size: 15px;
    }

    p {
      color: #999;
    }
  }

  .row {
    margin-bottom: 16px;
  }
}

/deep/ {
  .el-input {
    width: 85px;
    margin: 0 8px;
  }
}

.flexitem {
  display: inline-block;
}

.fixedbtn {
  position: fixed;
  bottom: 4%;
  left: 47%;
}
  
</style>
